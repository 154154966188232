
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import Header from '../components/header/Header.vue';
import AnimeItem from '../components/home/AnimeItem.vue';
import Anime from '../types/Anime';

export default defineComponent({
  components: { Header, AnimeItem },
  props: ['anime'],
  setup(props) {
    const listAnime = ref<Anime[] | null>(null);
    const route = useRoute();

    const { type } = route.params;
    listAnime.value = JSON.parse(props.anime);

    return { type, listAnime };
  }
});
